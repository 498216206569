export default () => {
  const productCarouselEls = [...document.querySelectorAll('.products-highlight .swiper')]

  if (productCarouselEls) {
    productCarouselEls.forEach((list, index) => {
      const desktopPerView = list.dataset?.desktopPerView
      const slidesPerViewNumber = desktopPerView ? Number(desktopPerView) : 2

      import(flu.chunks.swiper /* @vite-ignore */).then(function (_ref) {
        const Swiper = _ref.Swiper
        const Scrollbar = _ref.Scrollbar
        const Mousewheel = _ref.Mousewheel
        const swiper = new Swiper(list, {
          modules: [Scrollbar, Mousewheel],
          spaceBetween: 20,
          mousewheel: {
            forceToAxis: true
          },
          breakpoints: {
            992: {
              slidesPerView: slidesPerViewNumber,
            },
            320: {
              slidesPerView: 1.38,
            }
          },
          scrollbar: {
            el: '.js-scrollbar',
            draggable: true
          },
          on: {
            slideChange: function () {
              import('../public/datalayer.js').then(module => {
                module.scrollHandle()
              })
            }
          }
        })
      })
    })
  }
}