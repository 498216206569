export default () => {
  const handleVideoButton = () => {
    const videoMediaEls = document.querySelectorAll('.js-video-media')

    videoMediaEls.length && videoMediaEls.forEach(videoMediaEl => {
      const videoEl = videoMediaEl.querySelector('video');
      const buttonPlayPause = videoMediaEl.querySelector('.js-button-video')
      if (videoEl && buttonPlayPause) {
        buttonPlayPause.addEventListener('click', (e) => {
          e.preventDefault()

          if (videoEl.paused) {
            playVideo(videoEl, buttonPlayPause)
          } else {
            pauseVideo(videoEl, buttonPlayPause)
          }

          buttonPlayPause.setAttribute('aria-pressed', 'true')

          setTimeout(function (){
            buttonPlayPause.setAttribute('aria-pressed', 'false')
          }, 500)
        })
      }
    });
  }

  const pauseVideo = (video, button) => {
    video.pause()
    button.classList.remove('played')
  }

  const playVideo = (video, button) => {
    video.play()
    button.classList.add('played')
  }

  document.addEventListener("DOMContentLoaded", handleVideoButton())
}