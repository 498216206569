const ARTICLE_SELECTOR = '.js-article'
const VIDEO_WRAPPER_CLASS = 'article__video-wrapper'
const VIDEO_SECTION_CLASS = 'article__video'
const VIDEO_VERTICAL_CLASS = 'article__video--vertical'
const VIDEO_BUTTON_CLASS = 'article__video-button'
const VIDEO_CLASS = 'is-play'

export default () => {
  const articleEl = document.querySelector(ARTICLE_SELECTOR)
  if (!articleEl) return

  const videoEls = articleEl.querySelectorAll('video')

  if (videoEls && videoEls.length) {
    videoEls.forEach(video => {
      if (!video.parentNode) return

      const videoAspectRatio = video.getAttribute('data-aspect')
      const section = document.createElement('div')
      const wrapper = document.createElement('div')
      const button = document.createElement('button')
      wrapper.classList.add(VIDEO_WRAPPER_CLASS)
      button.classList.add(VIDEO_BUTTON_CLASS)
      video.parentNode.insertBefore(wrapper, video)
      wrapper.appendChild(video)
      wrapper.appendChild(button)

      section.classList.add(VIDEO_SECTION_CLASS)
      wrapper.parentNode.insertBefore(section, wrapper)
      section.appendChild(wrapper)

      if (videoAspectRatio && videoAspectRatio === 'vertical') {
        section.classList.add(VIDEO_VERTICAL_CLASS)
      }

      button.addEventListener('click', () => {
        if (video.paused) {
          video.play()
          wrapper.classList.add(VIDEO_CLASS)
        } else {
          video.pause()
          wrapper.classList.remove(VIDEO_CLASS)
        }
      })
    })
  }
}
