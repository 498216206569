export default () => {
  const couponContactEl = document.querySelector('.js-contact-form-klaviyo');

  if (couponContactEl) {
    const formEl = couponContactEl.querySelector('form')
    const buttonSubmitEl = formEl.querySelector('button[type="submit"]')
    const emailField = formEl.querySelector('input[type="email"]')
    const enableInbox =  couponContactEl.dataset.enableInbox

    if (!emailField) return

    buttonSubmitEl && buttonSubmitEl.addEventListener('click', (event) => {
      subcribeKlaviyo(event, emailField, couponContactEl, formEl, enableInbox)
    })
  }

  const checkRequired = (inputArr) => {
    let check = true
    inputArr.forEach((input) => {
      if (input.value.trim() === '') {
        check = false
      }
    })

    return check
  }

  const parseJSON = (string) => {
    let json = {}
    string = string.replace(/<!--.*-->/g, '')
    try {
      json = JSON.parse(string)
    } catch (e) {
      console.log(e)
    }
    return json
  }

  const subcribeKlaviyo = (event, emailField, couponContactEl, formEl, enableInbox) => {
    const inputEls = formEl.querySelectorAll('input[required]')
    const checkRequiredForm = checkRequired(inputEls)
    const isValid = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    const checkValid = isValid.test(emailField.value)
    const klaviyoListId = couponContactEl.dataset.klaviyo

    if (checkValid && klaviyoListId && checkRequiredForm) {
      event.preventDefault()
      event.target.disabled = true
    } else {
      return
    }

    const query = {
      email: emailField.value,
      g: klaviyoListId
    }

    window._learnq = window._learnq || []
    window._learnq.push(['identify', {
      '$email': emailField.value
    }])

    const body = Object.keys(query).map(k => {
      let value = query[k]
      return `${k}=${value}`
    }).join('&')

    fetch('https://manage.kmail-lists.com/ajax/subscriptions/subscribe', {
      method: 'POST',
      body: body,
      headers: {
        "content-type": "application/x-www-form-urlencoded",
        "cache-control": "no-cache"
      },
    })
      .then((response) => response.text())
      .then((responseText) => {
        const parsed = parseJSON(responseText)
        const isSubscribed = parsed.data.is_subscribed
        if (parsed && parsed['success']) {
          showSuccess(isSubscribed, formEl, enableInbox)
        } else {
          showError(formEl)
        }

        event.target.disabled = false
      })
  }

  const showSuccess = (isSubscribed, formEl, enableInbox) => {
    const successMessage = formEl.querySelector('.js-contact-form-subscribed')
    if (isSubscribed) {
      if (successMessage) successMessage.style.display = 'block'
    } else {
      if (enableInbox == 'true') {
        formEl.submit()
      } else {
        if (successMessage) successMessage.style.display = 'block'
      }
    }
  }

  const showError = (formEl) => {
    const errorMessage = formEl.querySelector('.js-contact-form-error')
    if (errorMessage) errorMessage.style.display = 'block'
  }
}
