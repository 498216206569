export default () => {
  document.addEventListener("DOMContentLoaded", function () {
    const targetNode = document.body
    const config = { attributes: true, childList: true, subtree: true }

    const callback = (mutationList, observer) => {
      for (const mutation of mutationList) {
        if (mutation.type === "childList") {
          updateTotalNumberText()
        }
      }
    }

    const observer = new MutationObserver(callback)
    observer.observe(targetNode, config)

    const updateTotalNumberText = () => {
      const totalReviewsEl = document.querySelectorAll('a.text-m')
      if (totalReviewsEl && totalReviewsEl.length) {
        totalReviewsEl.forEach(item => {
          if (item.textContent.includes('Review')) {
            addTotalsReviews(item)
            item.textContent = `(${item.textContent.split(' ')[0]})`
          }
        })
      }
    }

    const addTotalsReviews = (item) => {
      const yotpoBottomLineEl = document.querySelector('.yotpo-layout-header-wrapper .yotpo-bottom-line')
      const totalEl = yotpoBottomLineEl?.querySelector('.yotpo-bottom-totals')
      const node = document.createElement('div')
      node.classList.add('yotpo-bottom-totals')
      const span = document.createElement('span')
      const textNodeNumber = document.createTextNode(item.textContent.replace(/\D+/g, ''))
      span.appendChild(textNodeNumber)
      node.appendChild(span)
      node.append('total reviews')
      if (yotpoBottomLineEl && !totalEl) yotpoBottomLineEl.appendChild(node)
    }
  })

}