const MEGA_NAV_SELECTOR = '.js-meganav'
const MEGA_NAV_ITEMS_SELECTOR = '.js-meganav-items'
const MEGA_NAV_CONTENT_SELECTOR = '.js-meganav-content'
const MEGA_NAV_HOVER_SELECTOR = '.js-meganav-hover'
const MEGA_NAV_OPEN_FIRST_SELECTOR = '.js-open-first-meganav-content'
const ACTIVE_CLASS = 'is-active'
const MEGA_NAV_PADDING = 60

export default () => {
  const megaNavItems = document.querySelectorAll(MEGA_NAV_ITEMS_SELECTOR)
  const openFirstItemMenuEl = document.querySelector(MEGA_NAV_OPEN_FIRST_SELECTOR)

  const openMenu = (el, megaNavContentEl) => {
    el.classList.add(ACTIVE_CLASS)
    if (megaNavContentEl) megaNavContentEl.classList.add(ACTIVE_CLASS)
  }

  const initMegaMenu = () => {
    megaNavItems.forEach(megaNavItem => {
      const megaNavContentEl = megaNavItem.querySelector(MEGA_NAV_CONTENT_SELECTOR)

      if (megaNavItem && megaNavItem.classList.contains(ACTIVE_CLASS)) {
        megaNavItem.classList.remove(ACTIVE_CLASS)
      }

      if (megaNavContentEl && megaNavContentEl.classList.contains(ACTIVE_CLASS)) {
        megaNavContentEl.classList.remove(ACTIVE_CLASS)
      }
    })
  }

  const handleHeightMegaMenu = (megaNav, megaNavContentEl) => {
    const megaNavContentHeight = megaNavContentEl ? megaNavContentEl.offsetHeight : 0
    megaNav.style.height = `${megaNavContentHeight + MEGA_NAV_PADDING}px`
  }

  const openFirstItemMenu = (el) => {
    const megaNav = el.closest(MEGA_NAV_SELECTOR)
    if (!megaNav) return
    const megaNavHeight = megaNav.offsetHeight
    const megaNavContentHeight = el ? el.offsetHeight : 0
    megaNav.style.minHeight = `${megaNavHeight}px`
    megaNav.style.height = `${megaNavContentHeight + MEGA_NAV_PADDING}px`
  }

  if (megaNavItems && megaNavItems.length) {
    megaNavItems.forEach(megaNavItem => {
      const megaNavItemHover = megaNavItem.querySelector(MEGA_NAV_HOVER_SELECTOR)
      const megaNav = megaNavItem.closest(MEGA_NAV_SELECTOR)
      if (!megaNavItemHover && !megaNav) return
      const megaNavHeight = megaNav.offsetHeight
      megaNav.style.minHeight = `${megaNavHeight}px`

      megaNavItemHover.addEventListener('mouseenter', () => {
        const megaNavContentEl = megaNavItem.querySelector(MEGA_NAV_CONTENT_SELECTOR)
        initMegaMenu()
        openMenu(megaNavItem, megaNavContentEl)
        handleHeightMegaMenu(megaNav, megaNavContentEl)
      })
    })
  }

  if (openFirstItemMenuEl) {
    openFirstItemMenu(openFirstItemMenuEl)
  }
}
