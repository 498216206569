export default () => {
	window.addEventListener('keydown', (event) => {
		if (event.key === 'ArrowRight' || event.key === 'ArrowLeft' || event.key === 'Home' || event.key === 'End' && event.target.classList.contains('featured-collection-slider__navigation-list-item')) {
			if (event.key === 'ArrowRight') {
				const lengthEl = event.target.parentElement.parentElement?.children.length
				const lastEl = event.target.parentElement.parentElement?.children[lengthEl - 1]?.children[0]
				const nextEl = event.target.parentElement?.nextElementSibling
				if (nextEl !== null) {
					return nextEl?.children[0]?.focus()
				} else {
					return lastEl?.focus()
				}
			}

			if (event.key === 'ArrowLeft') {
				const firstEl = event.target.parentElement.parentElement?.children[0]?.children[0]
				const prevEl = event.target.parentElement?.previousElementSibling
				if (prevEl !== null) {
					return prevEl?.children[0]?.focus()
				} else {
					return firstEl?.focus()
				}
			}

			if (event.key === 'Home') {
				const firstEl = event.target.parentElement.parentElement?.children[0]?.children[0]
				if (firstEl && firstEl.length) return firstEl.focus()
			}

			if (event.key === 'End') {
				const lengthEl = event.target.parentElement.parentElement?.children.length
				const lastEl = event.target.parentElement.parentElement?.children[lengthEl - 1]?.children[0]
				if (lastEl && lastEl.length) return lastEl.focus()
			}

			document.activeElement.blur()
		}
	})
}