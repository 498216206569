export default () => {
  const announcementBarCarouselEls = [...document.querySelectorAll('.announcement-bar .swiper')]
  const button = document.querySelector('.announcement-bar .play-button')

  const initSlider = () => {
    if (announcementBarCarouselEls && announcementBarCarouselEls.length) {
      announcementBarCarouselEls.forEach((list, index) => {
        import(flu.chunks.swiper /* @vite-ignore */).then(function (_ref) {
          const enableAutoplay = list.dataset.autoplayEnabled;
          const autoplayDuration = list.dataset.autoplayDelay;
          const Swiper = _ref.Swiper,
              Navigation = _ref.Navigation,
              Autoplay = _ref.Autoplay;

          var swiperOptions = {
            modules: [Navigation],
            slidesPerView: 1,
            grabCursor: true,
            effect: "fade",
            fadeEffect: {
              crossFade: false
            },
            navigation: {
              nextEl: ".slider-nav-button-next",
              prevEl: ".slider-nav-button-prev"
            },
            watchSlidesProgress: false,
            loop: true,
            on: {
              init: function init() {
                list.parentElement.classList.add("slider-active")
              },
              slideChangeTransitionEnd: function slideChangeTransitionEnd() {
                var slideEls = this.slides;
                setTimeout(function () {
                  slideEls.forEach(function (slide) {
                    slide.toggleAttribute("inert", !slide.classList.contains("swiper-slide-active"));
                  });
                }, 50);
              }
            }
          };
          if (enableAutoplay === "true") {
            swiperOptions.modules.push(Autoplay);
            swiperOptions.autoplay = {
              delay: autoplayDuration,
              disableOnInteraction: false
            };
          }

          const swiper = new Swiper(list, swiperOptions)

          if (button) {
            button.addEventListener('click', (e) => {
              e.preventDefault()

              if (!button.classList.contains('played')) {
                swiper.autoplay.start()
                button.classList.add('played')
                button.setAttribute('aria-label', 'Pause')
              } else {
                button.classList.remove('played')
                button.setAttribute('aria-label', 'Play')
                swiper.autoplay.stop()
              }
            })
          }
        })
      })
    }
  }

  initSlider()
}