export default () => {
  var style = document.createElement( 'style' )
  style.innerHTML = `
    #locator-template .css-1xjr7ix { /* wrapper */
      position: relative;
      width: calc(100% + 32px);
      padding: 30px 0 33px;
      margin-left: -16px;
      margin-bottom: 12px;
      background: #E21B77;
    }
    #locator-template .css-12a5int,
    #locator-template .css-1vyur8l { font-size: 18px !important; line-height: 1.2 !important; }
    #locator-template .css-sbw1vw { /* label for input */
      color: #fff;
    }
    #locator-template .css-13otjrl { font-size: 11px !important; }
    #locator-template .css-17pm5ep{ margin-bottom: 16px; }
    #locator-template .css-6yu3c0 {
      background: transparent;
      border-color: #fff;
      color: #fff;
      border-radius: 6px;
    }

    #locator-template .chakra-tabs__tab-panels ul[role="list"]:not('.css-1ca0l9h'):not('.css-f5qjyp') li {
      height: 94px;
      border-color: #E21B77;
    }

    #locator-template .chakra-tabs__tab-panels ul#find-online-list,
    #locator-template .chakra-tabs__tab-panels ul#find-store-list {
      padding: 0;
    }

    #locator-template .chakra-tabs__tab-panels .chakra-button {
      border-color: #E21B77;
      border-radius: 6px;
      background: transparent;
    }

    #locator-template .chakra-tabs__tab-panels .chakra-text { font-size: 16px; }
    #locator-template .css-a6kold,
    #locator-template .chakra-tabs__tab-panels .css-lxqquz { background: #fff; font-size: 10px; align-self: center; margin-left: 4px; }

    chakra-scope #locator-template {
      background: transparent !important;
    }

    #locator-template header > div {
      margin-top: 0;
      padding: 38px 39px 32px 57px;
      background: #E21B77;
    }

    #locator-template .css-1xjr7ix .chakra-button { height: 43px; text-transform: uppercase; border-radius: 6px; }
    #locator-template header .chakra-text { color: #fff; font-size: 18px; text-transform: capitalize; }
    #locator-template header .css-j3i6ap {
      color: #fff;
      line-height: 1.2;
      height: auto;
    }
    #locator-template .css-g5e8j8 { width: 100%; }
    #locator-template .css-1j3ayur { width: 232px; height: 43px; text-transform: uppercase; }

    /* Main */
    #locator-template .chakra-portal > div,
    #locator-template main > div {padding-bottom: 0;}
    #locator-template .chakra-portal > div > div { background: #E21B77; width: calc(100% + 40px); margin-left: -20px; }
    #locator-template .chakra-tabs__tablist { margin: 0; }
    #locator-template .chakra-tabs__tab-panel { padding-top: 24px; }
    #locator-template .chakra-tabs__tablist button { padding: 24px; border-bottom-width: 4px; border-color: #F6F4F4; font-weight: 700; opacity: 0.75; }
    #locator-template .chakra-tabs__tablist button[aria-selected="true"] { color: #E21B77; border-color: #E21B77; opacity: 1; }
    #locator-template main .css-1jp955q { font-size: 16px; }
    #locator-template main .css-1jp955q + svg { font-size: 10px; }
    #locator-template main .css-69dl23 { margin: 8px 10px; height: 200px; }
    #locator-template #find-online-list li button { border-color: #E21B77; border-radius: 6px; background: transparent; }
    #locator-template .css-j3ylmx { flex-direction: row-reverse; }
    #locator-template .css-126n10b { width: 40%; }
    #locator-template .css-deiovz { width: calc(60% - 20px); padding-left: 0; padding-right: 20px; }
    #locator-template .css-gkg3bg { justify-content: space-between; margin: 0 -10px; }
    #locator-template #find-store-list li { height: 92px; border-color: #E21B77; border-radius: 6px; margin-top: 23px; padding: 17px 18px 17px 24px; }
    #locator-template .css-1yp4ln { align-items: center; }
    #locator-template .css-729ohl { height: 312px; }
    #locator-template .css-10aitac { margin-top: 3px; line-height: 1; }
    #locator-template .css-13z0bvs { background: #fff; }
    #locator-template .css-z9elw9 { background: #E21B77; }
    #locator-template .css-z9elw9 svg { color: #fff !important; }
    #locator-template .css-19urke7 { padding-bottom: 0; }
    #locator-template footer { display: none !important; }
    #locator-template .chakra-form-control label { color: #fff; }
    #locator-template .chakra-input { border-color: #fff; background: transparent; color: #fff; }
    #locator-template #popover-body .chakra-form-control label { color: #000; }
    #locator-template #popover-body .chakra-input { border-color: #000; background: transparent; color: #000; }
    #locator-template #popover-body .chakra-input__group .chakra-button { background: #E21B77 !important; }
    #locator-template #popover-body .chakra-input__group .chakra-button svg { color: #fff !important; }
    #locator-template .destini-css-1yiwvq8,
    #locator-template .destini-css-1ynwf5w,
    #locator-template .destini-css-wwsa90,
    #locator-template .destini-css-z1pqr6,
    #locator-template .destini-css-1qns9o4 { border-radius: 6px !important; text-transform: uppercase; }
    #locator-template #customSelect { border-color: #fff !important; box-shadow: none !important; }

    @media (min-width: 768px) {
      #locator-template .css-1xjr7ix {width: 100%; margin-left: 0;}
    }

    @media (min-width: 819px) {
      #locator-template main > div { flex-direction: row-reverse; }
      #locator-template main > div > div + div { padding-left: 0; padding-right: 25px; }
      #locator-template #find-online-list li button { padding-bottom: 60px; }
    }

    #locator-template .destini-css-j3i6ap { color: #fff; }
    #locator-template #header-language { margin-bottom: 15px !important; background:none; padding: 0; }
    #locator-template #header-language .chakra-popover__header { display: none !important; }
    #locator-template #header-language > button {border: 1px solid #E21B77;}
  `
  const destiniLocator = document.querySelector('#destini-locator')
  if (destiniLocator) {
    document.querySelector('#destini-locator').shadowRoot.appendChild( style )
  }
}