export default () => {
  const customerEl = document.querySelector('.customer')
  const formEls = customerEl ? Array.prototype.slice.call(customerEl.querySelectorAll('form')) : null
  if (formEls && formEls.length > 0) {
    formEls.map(el => {
      const inputEls = el.querySelectorAll('input[data-required="true"]')
      const buttonSubmitEl = el.querySelector('button[type="submit"]')
      buttonSubmitEl && buttonSubmitEl.addEventListener('click', (event) => {
        event.preventDefault()
        checkRequired(inputEls, el)
      })
    })
  }

  const newsletterPopupEl = document.querySelector('.popup__newsletter')
  if (newsletterPopupEl) {
    const inputEls = newsletterPopupEl.querySelectorAll('input[data-required="true"]')
    const buttonSubmitEl = newsletterPopupEl.querySelector('button[type="submit"]')
    buttonSubmitEl && buttonSubmitEl.addEventListener('click', (event) => {
      event.preventDefault()
      checkRequired(inputEls, newsletterPopupEl)
    })
  }

  const showError = (input, message) => {
    input.classList.add('input-error')
    const formControl = input.closest('text-input')
    formControl.classList.add('error')
    const small = formControl.querySelector('small')
    small.innerText = message
  }

  const showSuccess = (input) => {
    input.classList.remove('input-error')
    const formControl = input.closest('text-input')

    if (formControl && formControl.classList.contains('error')) {
      formControl.classList.remove('error')
    }
  }

  const checkRequired = (inputArr, form) => {
    let check = true
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    inputArr.forEach((input) => {
      if (input.value.trim() === '') {
        check = false
        showError(input,`Field is required`)
        if (input.type.toLowerCase() === 'email') {
          input.focus()
        }
      } else {
        showSuccess(input)
        if (input.type.toLowerCase() === 'email' ) {
          if(re.test(input.value.trim())) {
            showSuccess(input)
          } else {
            check = false
            showError(input,'Please enter a valid email address.')
          }
        }
      }
    })
    if (check) {
      form.submit()
    }
  }
}