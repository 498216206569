export default () => {
  const RECIPES_HERO_SELECTOR = '.js-recipes-hero-article'
  const SCROLLBAR_SELECTOR = '.js-scrollbar'
  const BUTTON_NEXT_SELECTOR = '.js-button-next'
  const BUTTON_PRVE_SELECTOR = '.js-button-prev'
  const ICON_HEIGHT = 38
  const recipesHeroArticleEls = [...document.querySelectorAll(RECIPES_HERO_SELECTOR)]

  if (recipesHeroArticleEls && recipesHeroArticleEls.length) {
    recipesHeroArticleEls.forEach((list) => {
      const scrollbarEl = list.querySelector(SCROLLBAR_SELECTOR)
      const nextEl = list.querySelector(BUTTON_NEXT_SELECTOR)
      const prevEl = list.querySelector(BUTTON_PRVE_SELECTOR)

      import(flu.chunks.swiper /* @vite-ignore */).then(function (_ref) {
        const Swiper = _ref.Swiper
        const Scrollbar = _ref.Scrollbar
        const Mousewheel = _ref.Mousewheel
        const Navigation = _ref.Navigation
        const swiper = new Swiper(list, {
          modules: [Scrollbar, Mousewheel, Navigation],
          spaceBetween: 16,
          navigation: {
            nextEl: nextEl,
            prevEl: prevEl
          },
          breakpoints: {
            1280: {
              slidesPerView: 3.07,
              spaceBetween: 40
            },
            992: {
              slidesPerView: 3.07,
              spaceBetween: 16
            },
            320: {
              slidesPerView: 1.6,
              spaceBetween: 16
            }
          },
          scrollbar: {
            el: scrollbarEl,
            draggable: true
          },
          on: {
            init: function () {
              alignArrowsToImages(list, prevEl, nextEl)
            },
            resize: function () {
              alignArrowsToImages(list, prevEl, nextEl)
            }
          }
        })
      })
    })

    const alignArrowsToImages = (el, prevEl, nextEl) => {
      if (!el) return
      const carouselImage = el.querySelector('img')
      if (prevEl && carouselImage) prevEl.style.top = `${(carouselImage.offsetHeight - ICON_HEIGHT) / 2}px`
      if (nextEl && carouselImage) nextEl.style.top = `${(carouselImage.offsetHeight - ICON_HEIGHT) / 2}px`
    }
  }
}
