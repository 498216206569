const INPUT_ACTIVE_CLASS = 'input-active'
const LABEL_SELECTOR = '.js-input-label'
const INPUT_SELECTOR = '.js-input'

export default () => {
  const textInput = document.querySelectorAll('[data-input-text]')

  if (textInput && textInput.length) {
    textInput.forEach(inputItem => {
      const labelEl = inputItem.querySelector(LABEL_SELECTOR)
      const inputEl = inputItem.querySelector(INPUT_SELECTOR)

      setTimeout(() => {
        initChange(inputEl, labelEl)
      }, 100)

      inputEl.addEventListener('change', () => {
        initChange(inputEl, labelEl)
      })
    })
  }

  const initChange = (inputEl, labelEl) => {
    if (inputEl.value !== '') {
      labelEl && labelEl.classList.add(INPUT_ACTIVE_CLASS)
    } else {
      labelEl && labelEl.classList.remove(INPUT_ACTIVE_CLASS)
    }
  }
}